import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import { Input, Select } from '../Shared/BsInput';
import ListTable from '../Shared/list-table';
import Modal from 'react-bootstrap4-modal';
import { useState } from 'react';
import * as BuildingsApi from '../../api/buildings';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as _ from 'lodash';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    marginTop: 40,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#37474f',
  },
}))(LinearProgress);

const RenderTable = ({ onPageChange, pages, page, buildings, onOpenModal }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Name" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Address" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Access types" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {buildings.map((b, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{b.id}</TableCell>
                <TableCell>{b.name}</TableCell>
                <TableCell>{b.address}</TableCell>
                <TableCell>{b.access_types.join(', ').toUpperCase()}</TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/app/buildings/${b.id}/edit`}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    Edit
                  </Button>
                  <Button
                    component={Link}
                    to={`/app/buildings/${b.id}/users`}
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Users" />
                  </Button>
                  <Button
                    component={Link}
                    to={`/app/buildings/${b.id}/companies`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Manage companies" />
                  </Button>
                  <Button
                    component={Link}
                    to={`/app/buildings/${b.id}/keys`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Access codes" />
                  </Button>
                  {b.access_source === 'manual-with-online-interaction' &&
                    <Button
                      onClick={() => onOpenModal(b.id)}
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{ marginBottom: '5px' }}
                      fullWidth
                    >
                      <FormattedMessage id="Synchronize Equipment" />
                    </Button>
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.id}
            onChange={handleChange}
            name="id"
            label={<FormattedMessage id="ID" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.address}
            onChange={handleChange}
            name="address"
            label={<FormattedMessage id="Address" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.access_source}
            onChange={handleChange}
            name="access_source"
            label={<FormattedMessage id="Access source" />}
            options={[
              { value: 'manual', label: <FormattedMessage id="Manual" /> },
              { value: 'online', label: <FormattedMessage id="Online" /> },
              {
                value: 'cenu-north',
                label: (
                  <FormattedMessage id="Access gateway: Cenu North Tower" />
                ),
              },
              {
                value: 'cenu-west',
                label: (
                  <FormattedMessage id="Access gateway: Cenu West Tower" />
                ),
              },
              {
                value: 'access-gateway',
                label: <FormattedMessage id="Access gateway: Iluminare" />,
              },
              {
                value: 'backed-access-gateway',
                label: (
                  <FormattedMessage id="Access gateway: Iluminare (show manual access code until ready)" />
                ),
              },
              {
                value: 'manual-with-online-interaction',
                label: <FormattedMessage id="Manual retrieved online" />,
              },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.order}
            onChange={handleChange}
            name="order"
            label={<FormattedMessage id="Order" />}
            options={[
              { value: 'id desc', label: 'ID desc' },
              { value: 'id asc', label: 'ID asc' },
              { value: 'name desc', label: 'Name desc' },
              { value: 'name asc', label: 'Name asc' },
              { value: 'address desc', label: 'Address desc' },
              { value: 'address asc', label: 'Address asc' },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            style={{ marginTop: '16px' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onPageChange, pages, page, buildings }) => {
  const [openModal, setOpenModal] = useState(false);
  const [buildingId, setBuildingId] = useState(0);
  const [usersSincronize, setUsersSincronize] = useState(0);
  const [progress, setProgress] = useState(0);
  const [usersError, setUsersErro] = useState([]);

  const sincronizeUsers = async () => {
    setProgress(1);
    const { data } = await BuildingsApi.getUsersExportGateway(buildingId);
    setUsersSincronize(data.length)
    sendUsersToGateway(data);
  }

  const sincronizeUsersFull = async () => {
    setProgress(1);
    const { data } = await BuildingsApi.getAllUsersExportGateway(buildingId);
    setUsersSincronize(data.length)
    sendUsersToGateway(data);
  }

  const onOpenModal = async (id) => {
    setProgress(0);
    setBuildingId(id);
    setUsersErro([]);
    setOpenModal(true);
  }

  const sendUsersToGateway = async (users) => {
    const totalusers = users.length;
    if( totalusers === 0 ) setProgress(100)
    let arrayUsers = [];
    for(let i=0; i < totalusers; i++) {
      await BuildingsApi.sendUsersGateway({
        ...users[i], 
        building_id: users[i]['Roles.building_id'] || users[i].building_id
      })
        .then(data => {
          if(data.error) console.error(data.error)
        })
        .catch((e) => {
          arrayUsers.push(users[i]);
        }
      );
      setUsersErro(arrayUsers);
      setProgress(((i+1) * 100)/totalusers);
    }
  }

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-building-o"></i>{' '}
          <FormattedMessage id="Buildings" />
        </Typography>
      }
      content={
        <React.Fragment>
          <div className="text-right">
            <Button
              component={Link}
              variant="contained"
              to={`/app/buildings/import-users`}
            >
              <FormattedMessage id="Import users" />
            </Button>
            &nbsp;
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/app/buildings/create`}
            >
              <FormattedMessage id="Create building" />
            </Button>
            <hr />
          </div>
          <Formik
            initialValues={{
              id: '',
              name: '',
              address: '',
              access_source: '',
              order: 'name asc',
            }}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await onPageChange({ page: 1, ...values });
                setSubmitting(false);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }}
            render={RenderForm}
          />
          <hr />
          {!buildings.length && <NoRecords />}
          {!!buildings.length && (
            <RenderTable {...{ onPageChange, pages, page, buildings, onOpenModal }} />
          )}
          <Modal visible={openModal} >
            <div className="modal-header">
              <h5 className="modal-title"><FormattedMessage id="Synchronizing equipment" /></h5>
            </div>
            <div className="modal-body">
              {progress === 0 ? (
                <>
                  <Button
                    onClick={sincronizeUsers}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '15px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Only the missing" />
                  </Button>
                  <Button
                    onClick={sincronizeUsersFull}
                    variant="outlined"
                    color="primary"
                    size="small"
                    fullWidth
                  >
                    <FormattedMessage id="Complete" />
                  </Button>
                </>
              ) : (
                <>
                  <p><FormattedMessage id="Wait while synchronizing equipment users" /></p>
                  <p style={{color: 'red'}}><FormattedMessage id="Do not close or update this window during the process" /></p>
                  <p><FormattedMessage id="Total users to be synced" />: {usersSincronize}</p>
                  {progress === 100 ? (usersError.length > 0 ?
                    <div style={{backgroundColor: '#ebe8e8'}}>
                      <p style={{color: 'red'}}><FormattedMessage id="The following users were not sent:" /></p>
                      {usersError.map(user =>
                        <div style={{color: 'red'}}>{`${user.name} ${user.surname}`} </div>
                      )}
                    </div>
                      : (<div style={{color: 'green'}}><FormattedMessage id="All people synced successfully" /></div>)
                  ):
                    <BorderLinearProgress variant="determinate" value={progress} />
                  }
                </>
              )}
            </div>
            <div className="modal-footer">
              <Button
                onClick={() => setOpenModal(false)}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="Close" />
              </Button>
            </div>
          </Modal>
        </React.Fragment>
      }
    />
  );
};
